.list {
    ul {
        margin: 0;
        background: white;
        padding: 0;
        list-style: none;

        li {
            display: block;
            position: relative;
            overflow: hidden;
            text-decoration: none;
            color: $dark-grey;
            box-sizing: border-box;
        }
    }
}
