.list {
    .pagination {
        padding: 26px;
        text-align: center;

        a {
            display: inline-block;
            position: relative;
            z-index: 2;
            border-radius: 50%;
            background: $grey;
            height: 36px;
            line-height: 40px;
            color: white;
            font-size: 14px;

            &:hover {
                background-color: $secondary;
            }

            &:first-child {
                margin-right: 12px;
                padding: 0 12px 0 10px;
            }

            &:last-child {
                margin-left: 12px;
                padding: 0 10px 0 12px;
            }
        }

        select {
            border: 1px solid $grey;
            border-radius: 18px;
            background-position: right 18px top 15px;
            padding: 0 34px 0 24px;
            height: 36px;
            font-weight: var(--medium);
        }

        a,
        select {
            margin: 0;
            vertical-align: middle;
            box-sizing: border-box;
        }
    }
}
