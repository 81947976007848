.list-header,
.list-footer {
    background: white;
}

.list-header {
    border-bottom: 1px solid $light-grey;
}

.list-footer {
    margin-top: -1px;
    border-top: 1px solid $light-grey;
}
