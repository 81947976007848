.list-sorter {
    &,
    a {
        color: $dark-grey;
        font-size: 14px;
        font-weight: var(--medium);
    }

    span {
        color: $dark-grey;
    }
}

.orderable {
    a,
    .inline {
        @extend %nowrap;

        display: inline-block;
        position: relative;
        padding-left: 16px;

        svg {
            position: absolute;
            top: 4px;
            left: 0;
            fill: $grey;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.orderable {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    svg {
        fill: $grey;
        margin-right: 10px;
    }
}

.active-up {
    svg {
        .directup {
            fill: $dark-grey;
        }
    }
}

.active-down {
    svg {
        .directdown {
            fill: $dark-grey;
        }
    }
}
