.list-header,
.list-footer {
    .count {
        display: block;
        flex-grow: 1;
        float: left;
        color: $grey;

        @include respond-to(mobile) {
            font-size: 14px;
        }
    }

    .limit {
        select {
            margin-left: 12px;
            font-size: 16px;
        }

        @include respond-to(mobile) {
            display: none;
        }
    }

    > .searchbox {
        float: left;
        margin-left: 0;

        input {
            width: 196px;
        }
    }

    .sorting {
        float: right;
        margin-right: 24px;
        height: 36px;

        .list-sorter {
            display: inline-block;

            .row-cell {
                display: none;
                margin-left: 10px;
                width: auto;
                line-height: 35px;

                &.orderable {
                    display: inline-block;
                }
            }

            .active-up {
                color: $brand;

                &::before {
                    @include triangle(8px 5px, $brand, up);
                }
            }

            .active-down {
                color: $brand;

                &::after {
                    @include triangle(8px 5px, $brand, down);
                }
            }
        }
    }
}

.list-header {
    .pagination {
        height: 32px;

        .offset {
            line-height: 32px;
        }
    }
}
