.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 0 24px;
    line-height: 28px;
    white-space: nowrap;

    .dummylink,
    a,
    button {
        display: inline-block;
        border: 1px solid $light-grey;
        border-radius: 4px;
        background: none;
        padding: 0;
        width: 28px;
        height: 28px;
        text-align: center;
        line-height: 24px;
        color: $dark-grey;
        font-weight: var(--medium);
        box-sizing: border-box;

        &.previous {
            border-radius: 4px 0 0 4px;
        }

        &.next {
            border-radius: 0 4px 4px 0;
        }
    }

    strong {
        color: $dark-grey;
        font-weight: var(--medium);
    }

    span {
        color: $grey;
    }

    a {
        &:hover {
            background-color: $lighter;
        }
    }

    .dummylink {
        &.previous {
            svg {
                fill: $light-grey;
            }
        }

        &.next {
            svg {
                fill: $light-grey;
            }
        }
    }

    .next,
    .previous,
    .refresh {
        position: relative;
    }

    .next,
    .previous {
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 6px;
            height: 8px;
            transform: translate(-50%, -50%);
            fill: $dark-grey;
        }
    }

    .previous {
        border-radius: 4px 0 0 4px;
    }

    .next {
        margin-left: -1px;
        border-radius: 0 4px 4px 0;
    }

    .previous,
    .refresh {
        margin-left: 12px;
    }

    .offset {
        margin-left: 24px;
    }

    .refresh {
        &:hover {
            svg {
                fill: $brand;
            }
        }

        svg {
            width: 14px;
            fill: $grey;
        }

        .loading {
            @include loading($brand, $light-grey, 10px);
            position: absolute;
            top: 5px;
            left: 5px;
        }
    }

    @include respond-to(mobile) {
        float: right;
        padding: 0;

        label {
            display: none;
        }
    }
}
