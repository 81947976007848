.list {
    .actions {
        text-align: center;

        span {
            &.id {
                display: block;
                margin-bottom: 30px;
                color: $dark-grey;
                font-size: 14px;
            }
        }
    }
}
