@import 'pagination';
@import 'ordering';

%list-header {
    @include clearfix;
    padding: 16px 24px;
    min-height: 56px;
    line-height: 32px;
    color: $grey;
    font-size: 16px;
    font-weight: var(--medium);
    box-sizing: border-box;

    .filter-dropdown {
        float: left;
        margin-right: 34px;
        cursor: pointer;
        color: $dark-grey;

        &::after {
            @include triangle(7px 5px, $dark-grey, down);
            position: absolute;
            margin-top: 16px;
            margin-left: 7px;
            content: '';
        }
    }

    .btn-info,
    .btn-success {
        float: right;
    }

    .actions {
        float: right;
        height: 28px;

        span {
            display: block;
            height: inherit;

            button {
                border: 1px solid $light-grey;
                border-radius: 4px;
                background: white;
                padding: 0;
                width: 28px;
                height: 28px;
                vertical-align: top;

                svg {
                    position: relative;

                    &.analytics {
                        top: -4px;
                        width: 14px;
                        fill: $light-grey;
                    }
                }

                &.active {
                    border-color: var(--warning);
                    background: var(--warning);

                    svg {
                        &.analytics {
                            fill: white;
                        }
                    }
                }
            }
        }
    }
}

.list-header,
.list-footer {
    @extend %list-header;
}
