html,
body {
    height: 100%;
}

body {
    @include font-rendering;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: var(--dark-grey);

    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow: hidden;
    font-size: 16px;
    font-weight: 400;
    overflow-y: auto;
    overflow-x: hidden;
}

input,
button,
select,
textarea,
optgroup,
option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}

figure {
    margin: 0;
}

p {
    margin-bottom: 16px;

    &.lead {
        font-size: 18px;
    }
}

svg {
    display: inline-block;
}

a,
.link, 
.btn-link {
    cursor: pointer;
    text-decoration: none;
    color: var(--brand);

    &:hover {
        text-decoration: underline;
    }
}

a,
button {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

small {
    color: $grey;
    font-size: 16px;
}

address {
    font-style: normal;
}

.loading {
    &,
    &::after {
        width: 14px;
        height: 14px;
    }

    @include loading($brand, $grey);

    h2 & {
        @include loading($brand, $grey);
        position: relative;
        top: 3px;
        margin: 0 14px 0 0;
    }
}
