.size-slider {
    display: flex;
    align-items: center;
    height: 16px;

    .rangeslider {
        margin: 0;
        height: 4px;

        .rangeslider__handle {
            top: -6px;
        }
    }

    svg {
        width: 14px;
        fill: $light-grey;

        &:first-child {
            margin-right: 10px;
        }

        &:last-child {
            margin-left: 10px;
        }
    }
}
