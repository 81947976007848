[role='button'] {
    cursor: pointer;
}

#{$all-buttons} {
    margin: 0;
    border: 0;

    &:focus {
        outline: none;
    }

    &.inline {
        @extend %inlinebutton;
    }
}

.btn {
    @include basebutton;

    background-color: white;
    padding: 0 20px;
    color: var(--text);
    font-weight: var(--medium);
    border: 1px solid var(--border);

    .loading {
        @include loading($brand, white, 10px);
    }

    &:hover {
        color: var(--brand);
    }

    &[disabled] {
        &:hover {
            background-color: $light;
        }
    }

    svg {
        position: relative;
        height: 16px;
        width: auto;
        margin: 0 8px;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }

        &:not([fill="none"]) {
            fill: $dark-grey;
        }
    }
}

.btn-medium {
    padding: 3px 24px;
    height: 32px;
    font-size: 14px;
}

.btn-small {
    padding: 0 14px;
    height: 28px;
    font-size: 14px;
}

.btn-large {
    border-radius: 25px;
    padding: 11px 14px 12px;
    height: 44px;
    font-weight: var(--medium);
}

.btn-loading {
    .loading {
        @include loading(white, white, 10px);
        top: 2px;

        &:first-child {
            margin-right: 6px;
        }

        &:last-child {
            margin-left: 6px;
        }
    }
}

.btn-square {
    border-radius: 0;
    height: 42px;

    svg {
        top: -3px;
        margin: 0 10px;
        height: 21px;
        vertical-align: middle;

        &:not([fill="none"]) {
            fill: white;
        }
    }
}

.btn-brand,
.btn-info {
    background: $info;
    color: white;
    border: none;

    svg {
        &:not([fill="none"]) {
            fill: white;
        }
    }

    &:focus,
    &:hover {
        border-color: $info;
        background-color: darken($info, $darken);
        color: white;
    }
}

.btn-brand-light,
.btn-info-light {
    &:focus,
    &:hover {
        border-color: $info;
        background: white;
        color: $info;
    }
}

.btn-brand-dark,
.btn-info-dark {
    background: darken($brand, $darken);
    color: white;
    border: none;

    &:focus,
    &:hover {
        background: darken($brand, $darken + 2.5);
        color: white;
    }
}

.btn-success {
    background: var(--success);
    color: white;
    border: none;

    svg {
        &:not([fill="none"]) {
            fill: white;
        }
    }

    &:focus,
    &:hover {
        border-color: var(--success);
        background-color: darken($success, $darken);
        color: white;
    }

    &[disabled] {
        &:focus,
        &:hover {
            background: var(--success);
            color: white;
        }
    }
}

.btn-success-light {
    svg {
        fill: var(--success);
    }
    &:focus,
    &:hover {
        color: white;
        border-color: var(--success);
        background: var(--success);
        svg {
            &:not([fill="none"]) {
                fill: white;
            }
        }
    }
}

.btn-danger {
    background: var(--danger);
    color: white;
    border: none;

    &:focus,
    &:hover {
        border-color: var(--danger);
        background-color: darken($danger, $darken);
        color: white;
    }
}

.btn-warning {
    background: var(--warning);
    color: white;
    border: none;

    svg {
        &:not([fill="none"]) {
            fill: white;
        }
    }

    &:focus,
    &:hover {
        border-color: var(--warning);
        background-color: darken($warning, $darken);
        color: white;
    }

    &[disabled] {
        &:focus,
        &:hover {
            background: var(--warning);
            color: white;
        }
    }
}

.btn-warning-light {
    svg {
        fill: var(--warning);
    }
    &:focus,
    &:hover {
        border-color: var(--warning);
        background: var(--warning);
    }
}

.btn-action {
    @extend %actionbutton;
    .loading {
        @include loading($brand, white, 28px);
    }
    &.btn-small {
        .loading {
            width: 16px;
            height: 16px;
        }
    }
}

.btn-request-settings {
    position: relative;
    float: right;
    border-radius: 0;
    background: none;
    padding: 0 0 0 24px;
    height: 32px;
    line-height: 32px;
    color: $brand;

    svg {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        margin-right: 6px;
        width: 16px;
        height: 16px;
        fill: $brand;
    }

    &:hover {
        background: none;
        text-decoration: underline;
        color: $brand;
    }
}

.btn-map-view {
    height: 88px;
    width: 100%;
}

// linked style buttons
.btn-link {
    @extend %inlinebutton;

    &.btn-brand {
        color: $brand;

        &:focus,
        &:hover {
            color: darken($brand, $darken);
        }
    }

    &.btn-success {
        color: var(--success);

        &:focus,
        &:hover {
            color: darken($success, $darken);
        }
    }

    &.btn-warning {
        color: var(--warning);

        &:focus,
        &:hover {
            color: darken($warning, $darken);
        }
    }

    &.btn-danger {
        color: var(--danger);

        &:focus,
        &:hover {
            color: darken($danger, $darken);
        }
    }

    &:hover {
        background: transparent;
        text-decoration: underline;
    }
}
