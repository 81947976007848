@import 'actions';
@import '../../shared/list/header/base';
@import 'header/base';

.list {
    background: $light;

    ul {
        li {
            h1 {
                color: $dark-grey;
                font-weight: normal;
            }

            figure {
                position: relative;
            }

            p {
                color: $brand;
                font-size: 16px;
            }

            a {
                color: $dark-grey;
            }
        }
    }
}

@import 'list-rows';
@import '../../shared/list/pagination';
