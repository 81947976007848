@mixin actionbuttonsmall {
    width: 24px;
    height: 24px;
    line-height: 20px;
    padding: 0;

    svg {
        width: 12px;

        &.plus {
            width: 12px;
            height: 12px;
        }

        &.checkmark {
            width: 12px;
            height: 10px;
        }

        &.close {
            width: 10px;
            height: 10px;
        }
    }
}

%actionbutton {
    @include basebutton;
    position: relative;
    border: 1px solid var(--border);
    background: white;
    padding: 0 !important;
    width: 36px;
    height: 36px;
    line-height: 34px;
    color: $grey;
    font-size: 15px;

    svg {
        height: auto;
        width: 10px;

        &:not([fill="none"]) {
            fill: $grey;
        }

        &.chevron {
            width: 7px;
        }

        &.checkmark {
            width: 14px;
            height: 12px;
        }

        &.close {
            width: 14px;
            height: 14px;
        }

        &.plus,
        &.variations {
            width: 14px;
        }

        &.deliveryvan {
            width: 16px;
        }

        &.zoom,
        &.settings {
            width: 16px;
        }

        &.trash {
            width: 15px;
        }

        &.priceable {
            width: 11px;
            height: 16px;
        }
    }

    &.active,
    &:hover {
        border-color: $brand;
        background: $brand;
        color: white;

        svg {
            &:not([fill="none"]) {
                fill: white;
            }
        }
    }

    &.btn-small {
        @include actionbuttonsmall;
    }

    &:hover,
    &.active {
        small {
            border-color: white;
            background-color: darken($success, $darken);
        }
    }

    &.btn-signin,
    &.btn-menu,
    &.btn-variation,
    &.btn-change,
    &.btn-settings {
        svg {
            fill: $dark-grey;
        }
    }

    &.btn-change {
        &.open {
            transform: rotate(-180deg);
        }
    }

    &.btn-variation {
        display: inline-flex;
        padding: 0 8px;
        width: auto;
        text-decoration: none;
        line-height: 20px;
        font-size: 13px;
        font-weight: 500;

        svg {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
        }

        span {
            display: inline-block;
            margin: 0 0 0 6px;
            padding: 1px 0;
            line-height: 20px;
            color: $dark-grey;
            font-size: 13px;
            font-weight: 500;
        }

        &:hover,
        &.active {
            border-color: $dark-grey;
            background-color: $dark-grey;

            span {
                color: white;
            }
        }
    }

    &.btn-warning {
        background-color: white;

        svg {
            fill: var(--warning);
        }

        &:hover {
            border-color: var(--warning);
            background-color: var(--warning);
        }
    }

    &.btn-info {
        svg {
            fill: $info;
        }

        &:hover {
            border-color: $info;
            background-color: $info;
        }
    }

    &.btn-success {
        svg {
            transition: opacity $fast;
            fill: white;

            &.close {
                display: none;
            }
        }

        &:hover {
            border-color: var(--success);
            background: var(--success);
        }

        &.active {
            border-color: var(--success);
            background-color: var(--success);

            &:hover {
                border-color: var(--warning);
                background-color: var(--warning);

                svg {
                    &:not(:only-child):first-child {
                        display: none;
                    }

                    &.close {
                        display: block;
                    }
                }
            }
        }
    }
}
